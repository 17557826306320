import React from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import { Space } from "antd";

import {
  BannerPage,
  ButtonPrimary,
  Colors,
  ContainerContent,
  ContainerContentFixed,
  ContainerCta,
  ContentDisclaimer,
  InternalLink,
  InternalLinkTypes,
  Layout,
  SEO,
} from "../components";
import { DisplayInfoBar } from "../components";

export default ({ data }) => {
  const floorplan = data.allFloorPlansJson.nodes[0];
  return (
    <Layout>
      <SEO title={`${floorplan.name} ${floorplan.info.size} Floor Plan`} />
      <BannerPage
        title={`${floorplan.name} ${floorplan.info.size}`}
        subTitle={
          <div>
            <Space direction={"vertical"} size={"large"}>
              <span>
                <b>FITS LOT:</b> {floorplan.info.lotWidth}m x{" "}
                {floorplan.info.lotLength}m
              </span>

              <DisplayInfoBar
                living={floorplan.info.living}
                garage={floorplan.info.carSpace}
                bed={floorplan.info.bed}
                storey={floorplan.info.storey}
                bath={floorplan.info.bath}
              />
              {floorplan.displayLink && (
                <ButtonPrimary>
                  <InternalLink url={floorplan.displayLink}>
                    See Display Home
                  </InternalLink>
                </ButtonPrimary>
              )}
            </Space>
          </div>
        }
      />
      <ContainerContent>
        <ContainerContentFixed>
          <div style={{ padding: "1rem" }}>
            <NonStretchedImage
              fluid={floorplan.floorPlanImagePath.childImageSharp.fluid}
            />
          </div>{" "}
          <section style={{ textAlign: "center" }}>
            <ContentDisclaimer />
          </section>
        </ContainerContentFixed>
      </ContainerContent>
      {floorplan.info.areas && (
        <ContainerContent style={{ backgroundColor: Colors.LightGray }}>
          <ContainerContentFixed>
            <div style={{ textAlign: "center" }}>
              <h4>
                {floorplan.name} {floorplan.info.size} Areas
              </h4>
              <table style={{ maxWidth: "400px", margin: "auto" }}>
                <tbody>
                  <tr>
                    <td>
                      {floorplan.info.storey === 1
                        ? "Residence"
                        : "Ground Floor"}
                    </td>
                    <td>{floorplan.info.areas.groundFloor.size} sq.m</td>
                    <td>{floorplan.info.areas.groundFloor.area} sqrs</td>
                  </tr>
                  {floorplan.info.storey === 2 && (
                    <tr>
                      <td>First Floor</td>
                      <td>{floorplan.info.areas.firstFloor.size} sq.m</td>
                      <td>{floorplan.info.areas.firstFloor.area} sqrs</td>
                    </tr>
                  )}
                  <tr>
                    <td>Garage</td>
                    <td>{floorplan.info.areas.garage.size} sq.m</td>
                    <td>{floorplan.info.areas.garage.area} sqrs</td>
                  </tr>
                  <tr>
                    <td>Porch</td>
                    <td>{floorplan.info.areas.porch.size} sq.m</td>
                    <td>{floorplan.info.areas.porch.area} sqrs</td>
                  </tr>
                  <tr>
                    <td>Outdoor Living</td>
                    <td>{floorplan.info.areas.outdoorLiving.size} sq.m</td>
                    <td>{floorplan.info.areas.outdoorLiving.area} sqrs</td>
                  </tr>
                  {floorplan.info.areas.balcony && (
                    <tr>
                      <td>Balcony</td>
                      <td>{floorplan.info.areas.balcony.size} sq.m</td>
                      <td>{floorplan.info.areas.balcony.area} sqrs</td>
                    </tr>
                  )}
                  <tr>
                    <td>Total Area</td>
                    <td>{floorplan.info.areas.totalArea.size} sq.m</td>
                    <td>{floorplan.info.areas.totalArea.area} sqrs</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ContainerContentFixed>
        </ContainerContent>
      )}
      <ContainerCta
        title={"Can't find the floor plan you like?"}
        subTitle={"Don't worry, we can build one as per your needs"}
        primaryLink={InternalLinkTypes.FloorPlans}
        primaryText={"See Other Floor Plans"}
        secondaryText={"Customise your own"}
        secondaryLink={InternalLinkTypes.GetStarted}
      />
    </Layout>
  );
};
const NonStretchedImage = props => {
  let normalizedProps = props;
  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        maxWidth: props.fluid.presentationWidth,
        margin: "0 auto", // Used to center the image
      },
    };
  }

  return <Img {...normalizedProps} />;
};
export const query = graphql`
  query($path: String!) {
    allFloorPlansJson(filter: { slug: { eq: $path } }) {
      nodes {
        name
        displayLink
        info {
          size
          storey
          bed
          bath
          living
          carSpace
          site
          totalSquares
          lotWidth
          lotLength
          areas {
            groundFloor {
              size
              area
            }
            firstFloor {
              size
              area
            }
            garage {
              size
              area
            }
            porch {
              size
              area
            }
            balcony {
              size
              area
            }
            outdoorLiving {
              size
              area
            }
            totalArea {
              size
              area
            }
          }
        }

        floorPlanImagePath {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
  }
`;
